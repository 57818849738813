@import './functions';
@import './color';
@import './layout';
@import './typography';

.header-dropwdown {
	text-decoration: none;
	text-transform: none;
	border: 0;
	max-width: none;
	line-height: 1;
	display: block;
	padding: 18px;
	color: #000;
	transition: none;
	font-size: 1.14286rem;

	@media #{$large-only} {
		font-size: 1rem;
		padding-bottom: 21px;
	}

	@media #{$medium-only} {
		font-weight: bold;
		font-size: 0.78571rem;
		line-height: 1.57143rem;
		letter-spacing: 0.05em;
		padding: 0 0 19px;
	}
}

.header-subdropwdown {
	text-decoration: none;
	text-transform: none;
	border: 0;
	max-width: none;
	line-height: 1;
	display: block;
	color: #000;
	transition: none;

	@media #{$large-only} {
		padding-bottom: 21px;
	}

	@media #{$medium-only} {
		font-weight: bold;
		line-height: 1.57143rem;
		letter-spacing: 0.05em;
		padding: 0 0 19px;
	}
}

.header-language-selector {
	font-size: 1.3rem;

	@media #{$large-up} {
		font-size: 0.75rem;
	}

	@media #{$medium-only} {
		font-size: 0.75rem;
	}
}

.header-search-button {
	font-size: 1.14286rem;
	font-weight: bold;
	line-height: 1.0rem;
	margin-left: 5px;

	@media #{$large-only} {
		font-size: 1rem;
		padding-bottom: 21px;
	}

	@media #{$medium-only} {
		font-weight: bold;
		font-size: 0.78571rem;
		line-height: 1.57143rem;
		letter-spacing: 0.05em;
		padding: 0 0 19px;
	}
}
