@import './functions';
@import './color';
@import './layout';
@import './typography';

.product-blocks {
	margin: 0 18px;
	padding: 20px 0 0;
	border-bottom: 1px solid $product-blocks-title;

	@media #{$medium-up} {
		padding-top: 35px;
	}

	&__title {
		@extend %product-h3;
		margin-bottom: 20px;
		text-align: center;
		text-decoration: none;

		@media #{$medium-up} {
			margin-bottom: 35px;
		}
	}

	&__toggler {
		float: right;
		margin-top: -40px;
		line-height: 1;
		border-bottom: 0 none;
		font-weight: $font-weight-bold;
		font-size: rem-calc(10);
		text-transform: uppercase;

		@media #{$medium-up} {
			margin-top: -50px;
		}

		&:hover {
			color: $product-blocks-toggler;
		}

		&:before {
			content: attr(data-show-txt);
			display: inline-block;
		}

		&:after {
			display: inline-block;
			margin: {
				bottom: -3px;
				left: 5px;
			}
		}
	}

	&__list {
		transition: max-height 0.5s ease;
		overflow: hidden;
		max-height: 0;
		margin: 0;
		padding: 0;
		list-style: none;

		&.flex {
			max-height: unset !important;
			display: block;

			@media #{$medium-up} {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
			}
		}
	}

	&._is-small-open {
		@media #{$small-only} {
			border-color: transparent;
		}
	}

	&._is-medium-open {
		@media #{$medium-up} {
			border-color: transparent;
		}
	}

	&._is-small-open &__toggler {
		@media #{$small-only} {
			&:before {
				content: attr(data-hide-txt);
			}

			&:after {
				// @include css-triangle(
				// 	5px,
				// 	$product-blocks-toggler,
				// 	bottom
				// );
				display: inline-block;
				margin: {
					bottom: -3px;
					left: 5px;
				}
				position: relative;
				top: -5px;
			}
		}
	}

	&._is-medium-open &__toggler {
		@media #{$medium-up} {
			&:before {
				content: attr(data-hide-txt);
			}

			&:after {
				display: inline-block;
				margin: {
					bottom: -3px;
					left: 5px;
				}
				position: relative;
				top: -5px;
			}
		}
	}

	&._is-small-open &__list {
		@media #{$small-only} {
			max-height: 1000px;
		}
	}

	&._is-medium-open &__list {
		@media #{$medium-up} {
			max-height: 1000px;
		}
	}
}

.product-block {
	&.list-item {
		border: 0 none;
		padding: 0;
	}

	.list-item {
		float: none;
		width: 100%;
	}

	.list-item__title {
		font: {
			family: 'Bembo';
			size: rem-calc(20);
		}
		line-height: rem-calc(24);
	}

	.list-item__description {
		@media #{$medium-up} {
			display: block;
		}
	}

	img {
		width: 100%;
	}
}
