%product-h1 {
	font: {
		family: 'Bembo';
		weight: normal;
		size: rem-calc(28);
		style: normal;
	}
	line-height: rem-calc(43);

	@media #{$medium-up} {
		font-size: rem-calc(40);
	}
}

%product-h1-alt {
	font: {
		family: 'Bembo';
		weight: normal;
		size: rem-calc(28);
		style: normal;
	}
	line-height: rem-calc(43);

	@media #{$medium-up} {
		font-size: rem-calc(32);
	}
}

%product-h2 {
	font: {
		family: 'Bembo';
		size: rem-calc(18);
		weight: normal;
		style: normal;
	}
	line-height: rem-calc(28);

	@media #{$medium-up} {
		font-size: rem-calc(23);
	}
}

%product-h3 {
	font: {
		family: 'Bembo';
		size: rem-calc(18);
		weight: normal;
		style: normal;
	}
	line-height: rem-calc(26);
	text-decoration: underline;

	@media #{$medium-up} {
		font-size: rem-calc(29.9);
	}
}

.product-h1 {
	@extend %product-h1;
}

.product-h1-alt {
	@extend %product-h1-alt;
}

.product-h2 {
	@extend %product-h2;
}

.product-h3 {
	@extend %product-h3;
}
