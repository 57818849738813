$black: #000;
$white: #fff;
$aluminum: #a6a7ab;
$light-gray: #ddd;
$grey: #808080;
$mercury: #e5e5e5;
$logo-bg: #f0f0f0;
$greyBorder: $light-gray;
$body-font-colour: $black;
$header-font-colour: $black;
$link-hover: $aluminum;
$link-border: $black;
$link-border-hover: $aluminum;
$input-empty: $white;
$input-active: $white;
$input-border: $black;
$button-background: $black;
$button-hover-background: $white;
$product-blocks-title: $aluminum;
$product-blocks-toggler: $black;
