#breathing-icon {
	width: 25%;
	margin: auto;
	border: 1px solid #ffffff00;
	-webkit-animation: breathing 2s linear infinite
		normal;
	animation: breathing 2s linear infinite normal;
	-webkit-font-smoothing: antialiased;
	text-align: center;
}

@-webkit-keyframes breathing {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	25% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	50% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}

	75% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes breathing {
	0% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}

	25% {
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		transform: scale(0.9);
	}

	50% {
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
	}

	75% {
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		transform: scale(0.9);
	}

	100% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}
}
