@import './functions';
@import './color';
@import './layout';
@import './typography';


.dropdown {
	position: relative !important; 
	font-size: '12px !important';
	font-weight: bold !important;
	text-transform: none !important;
	transition: all 0.3s ease !important; 
	text-align: center !important;
	display: inline-block !important;
	width: 'auto !important';
	white-space: nowrap !important;
}

.dropdown:hover::after {
	content: ''; 
	position: absolute; 
	bottom: 0; 
	left: 0; 
	width: 100%; 
	height: 3px; 
	background-color: black; 
}

.dropdown-menu {
	position: absolute;
	top: 100%; 
	left: 0; 
	z-index: 1000; 
	background-color: white;
	border: 1px solid #000;
	padding: 7px 0;
}

.dropdown-menu-right {
	position: absolute;
	top: 100%; 
	right: 0; 
	z-index: 1000; 
	background-color: white;
	border: 1px solid #000;
	padding: 7px 0;
}

.dropdown-menu ul,
.dropdown-menu-right ul {
	list-style-type: none; 
	padding: 0; 
	margin: 0; 
	font-weight: bold !important;
}

.dropdown-menu li,
.dropdown-menu-right li {
	padding: 6px 20px;
	font-size: 11px;
	font-weight: 300;
	white-space: nowrap;
}

.dropdown-menu li:hover,
.dropdown-menu-right li:hover {
	background-color: #f2f2f2;
}

.dropdown-menu li,
.dropdown-menu-right li {
	padding: 6px 20px;
	font-size: 1rem;
	font-weight: 300;
	white-space: nowrap;
	line-height: 1.5rem;

	@media #{$medium-up} {
		font-size: 0.95rem;
	}

	@media #{$small-only} {
		font-size: 0.8rem;
		line-height: 1.5;
	}
}

.subdropdown {
	position: relative !important; 
	color: #4d4e52 !important;
	text-transform: none !important;
	transition: all 0.3s ease !important; 
	// text-align: center !important;
	padding: 6px 20px !important;
	display: inline-block !important;
	width: 'auto !important';
	white-space: nowrap !important;
	line-height: 1.5rem !important;
}


.subdropdown-menu {
	position: absolute;
	top: 0; 
	left: 100%; 
	z-index: 1000; 
	background-color: white;
	border: 1px solid #000;
	// padding: 7px 0;
}

.subdropdown-menu ul {
	list-style-type: none; 
	padding: 0; 
	margin: 0; 
	font-weight: bold !important;
}

.subdropdown-menu li {
	padding: 6px 20px;
	font-size: 11px;
	font-weight: 300;
	white-space: nowrap;
}

.subdropdown-menu li:hover {
	background-color: #f2f2f2;
}

.subdropdown-menu li {
	padding: 6px 20px;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
	white-space: nowrap;
	line-height: 1.5rem;

	@media #{$medium-up} {
		font-size: 0.95rem;
	}

	@media #{$small-only} {
		font-size: 0.8rem;
		line-height: 1.5;
	}
}
