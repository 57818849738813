
$base-font-size: 14px;
$rem-base: $base-font-size;
$include-html-classes: true;
$include-html-global-classes: $include-html-classes;
$include-xl-html-grid-classes: true;
$row-width: 100%;
$total-columns: 12;
$column-gutter: rem-calc(4);
$font-family-sans-serif: 'Avenir',
	'Helvetica Neue', Helvetica, Roboto, Arial,
	sans-serif;
$font-weight-bold: 700;
$font-weight-bolder: 900;
$body-bg: $white;
$body-font-color: $body-font-colour;
$small-breakpoint: 768px;
$medium-breakpoint: 1024px;
$large-breakpoint: 1440px;
$small-range: (0, $small-breakpoint);
$medium-range: (
		$small-breakpoint + 1,
	$medium-breakpoint
);
$large-range: (
		$medium-breakpoint + 1,
	$large-breakpoint
);
$screen: 'only screen';
$small-up: $screen;
$small-only: '#{$screen} and (max-width: #{upper-bound($small-range)})';
$medium-up: '#{$screen} and (min-width:#{lower-bound($medium-range)})';
$medium-only: '#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})';
$large-up: '#{$screen} and (min-width:#{lower-bound($large-range)})';
$large-only: '#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})';


