@import './functions';
@import './color';
@import './layout';
@import './typography';

%old-price {
	padding-left: 12px;
	text-decoration: line-through;
	color: $grey;
}

.product {
	margin-bottom: 0;

	@media #{$small-only} {
		text-align: center;
	}

	&__name {
		@extend %product-h1;
		display: block !important;
		padding-bottom: 16px !important;

		@media #{$small-only} {
			font-size: rem-calc(36) !important;
			line-height: 1.2 !important;
			padding-bottom: 8px !important;
		}
		@media #{$medium-up} {
			font-size: rem-calc(32) !important;
			line-height: 1 !important;
		}
	}

	&__description {
		@extend %product-h2;
		display: block !important;
		padding-bottom: 16px !important;

		@media #{$small-only} {
			font-size: rem-calc(18) !important;
			padding-bottom: 8px !important;
		}

		@media #{$medium-up} {
			font-size: rem-calc(14) !important;
			line-height: 1.4 !important;
		}
	}

	&__price {
		@extend %product-h2;
		display: block;

		@media #{$small-only} {
			font-size: rem-calc(18);
		}

		.was-price {
			@extend %old-price;
		}
	}

	&__total {
		@extend %product-h2;
		display: flex;
		justify-content: space-between;
		border-top: 1px solid $mercury;
		padding: rem-calc(12 0 8);
		.was-price {
			@extend %old-price;
		}
	}
}

.product-price {
	margin-bottom: 25px;

	@media #{$medium-up} {
		margin-bottom: 32px;
	}

	@media #{$small-only} {
		text-align: center;
	}
}

@media only screen and (max-width: 767px) {
	.product__name {
		font-size: rem-calc(28);
	}

	.product__name,
	.product__price {
		line-height: 1;
	}
}
