.carousel-pagination {
	/* background-color: black; */
	display: flex;
	justify-content: center;
	padding: 21px 0;
}
:root {
	--swiper-theme-color: #000; /* Cambia esto al color que desees */
}

.swiper-button-next {
	background-color: white;
	border-radius: 100px;
	width: 30px;
	height: 30px;
	margin-right: 15px;
	transition: background-color 0.3s ease;
}

.swiper-button-next:hover {
	background-color: #ffffffc8;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
	content: 'next';
	font-size: 15px;
}

.swiper-button-prev {
	background-color: white;
	border-radius: 100px;
	width: 30px;
	height: 30px;
	margin-right: 15px;
	transition: background-color 0.3s ease;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-prev:after {
	content: 'prev';
	font-size: 15px;
}

.swiper-button-prev:hover {
	background-color: #ffffffc8;
}
